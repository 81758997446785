<template>
  <div>
    <portal to="header">
      <terminal-header
          :title="$t('Picking')"
          :out-focused="isBlocked"
          @barcode="handleBarcode"
      />
    </portal>
    <transition-group name="terminal-item" v-if="queue && !action">
      <div
          v-for="(item, i) in queue.objects"
          :key="`${item.type}:${i}`"
          class="q-mb-sm"
      >
        <component
            :is="dynamicComponents[item.type]"
            v-bind="{ data: item.data, rawData: item }"
            @click="handleClick"
        />
      </div>
    </transition-group>

    <div v-if="action">
      <product-section :data="item" />

      <quantity-section
          :data="quantities"
          :options="quantityOptions"
          @click="handleEvent"
      />
      <counter-section :data="counter" @click="handleEvent" />
    </div>

    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>

  </div>
</template>

<script>


// Vuex
import { mapMutations } from 'vuex'

export default {
  name: 'RuledAllocationQueue',
  data () {
    return {
      dynamicComponents: {
        'assistant': 'assistant-object',
        'message': 'picking-message',
        'Orderadmin\\Storage\\Entity\\Item': 'item-section',
        'collection': 'dynamic-collection',
      },
      data: null,
      counter:null,
      action: false,
      queue: null,
      isBlocked: false,
      barcode: null,
      place: null,
      product: null,
      offerId:'',
      amount: 0,
      quantity: 1,
      assistantObject: {
        title: 'Please scan',
        caption: 'Item',
        captionColor: 'amber',
        image: 'scanBasketPlace'
      },
      objects: {},
      item: null
    }
  },
  computed: {
    tilesSchema () {
      return []
    },
    quantities () {

      return {
        max: this.counter.max,
        current: this.counter.current
      }
    },
    quantityOptions () {
      return {
        maxDescription: 'tap to add all',
        max: 'Available',
        current: 'Scanned'
      }
    },
  },
  mounted() {
    let item = {}
    item.event = 'storage.tasks.queue.opened'
    const data ={
      event: item.event,
      timestamp: new Date().toISOString(),
      entity: this.$route.params.id,
      entityClass: this.$entities.Orderadmin_Storage_Entity_Picking_Queue
    }
    return this.$service.picking.process(this.$route.params.id, data)
        .then(data => {
          this.queue = data
          this.data = this.queue
        })
        .catch(err => {
          this.error = err.message
        })
  },
  unmounted () {
    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }

    this.deleteAllHelpers()
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'deleteAllHelpers'
    ]),
    handleBlock () {
      this.isBlocked = true
    },
    handleUnblock () {
      this.isBlocked = false
    },
    handleReset () {
      this.isBlocked = false
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.deleteAllHelpers()
      this.place = null
    },
    handleClick (item) {
      console.log(item)
      if (!item.event) {
        return
      }

      const events = {
        focus: () => {
          this.$emit('block')
        },
        focusOut: () => {
          this.$emit('block')
        },
        quantityChange: () => {
          this.quantity = item.data.quantity
        },
        counterSubmit: () => {
          this.quantity = item.data.quantity
          this.handleBarcode({ raw: item.data.barcode, value: item.data.barcode })
        },
        'storage.tasks.queue.opened': () => {
          this.queue = item.data
          this.data.entityClass = item.type
          this.data.event = item.event
          this.sequence = item.sequence

          this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
        },
        'storage.tasks.task.offer.opened': () => {
          this.offerId = item.data.id
          this.handleBarcode({ value: this.barcode.raw, raw: this.barcode.raw, type: '' })
        },
        'storage.queue.task.reject': () => {
          this.data.event = 'storage.queue.task.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Picking_Task

          this.handleBarcode({ value: '', raw: '', type: '' })
        }
      }

      if (events[item.event]) {
        return events[item.event]()
      }

      this.data.entityClass = item.type
      this.data.event = item.event

      this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
    },
    handleBarcode (barcode) {
      this.barcode = barcode
      const taskColName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Picking_Task)

      const data = this.reduceData(
          [
            'objects',
            'queue',
            'requiredData',
            'requiredDataType',
            'maxCount',
            'entity',
            'timestamp',
              'quantity',
              'task',
              'barcode'
          ],
          {
            timestamp: new Date().toISOString(),
            entity: barcode.raw
          }
      )
      if (this.amount) {
        data.quantity = this.amount
      }
      if (!data.quantity && this.data.event === 'storage.place.opened'){
        this.action = true
        return
      }


      if (this.sequence && !this.data.sequence) {
        data.sequence = this.sequence.id
      }

      if(this.data.event === 'storage.tasks.task.offer.opened' && this.data.entityClass ===`Orderadmin\\Storage\\Entity\\Item` && this.offerId){
        data.offer = this.offerId
        this.offerId = ''
      }

      if (this.data.event === 'storage.place.opened') {
        const resultBarcode = this.data.objects.filter(e=> e.type === 'barcode')
        data.barcode = resultBarcode[0].data
      }

      if (this.objects[taskColName]) {
        data.task = this.objects[taskColName].data.map(({ id }) => id)
      }

      const task = this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task]
          ? { ...this.objects[this.$entities.Orderadmin_Storage_Entity_Picking_Task] }
          : null

      if (!data.task && task) {
        data.task = task.data.id
      }

      const placeCollection = this.getPlaceCollection()

      if (placeCollection) {
        data.places = placeCollection.data.map(({ id }) => id)
      }

      if (this.objects[this.$entities.Orderadmin_Storage_Entity_Item]) {
        data.item = this.objects[this.$entities.Orderadmin_Storage_Entity_Item].data.id
      }

      const place = this.getPlaceObject()

      if (place && !data.places) {
        data.places = [place.data.id]
      }
      return this.$service.picking.process(this.$route.params.id, data)
          .then(data => {
            this.objects = data.objects.reduce((acc, obj) => {
              const key = obj.type === 'collection'
                  ? `collection:${obj.entityClass}`
                  : obj.type

              acc[key] = obj

              return acc
            }, {})

            this.queue = data
            this.data = data
            this.action = false
            const receivedItem = data.objects.filter(e=> e.type === "Orderadmin\\Products\\Entity\\Product\\Offer")
            this.item = receivedItem[0].data
            const counter = data.objects.filter(e=> e.type === "counter")
            this.counter = counter[0].data
          })
          .catch(err => {
            this.amount = 0
            this.error = err.message
          })
    },
    getPlaceObject () {
      const entity = this.$entities.placeObjects.find(x => this.objects[x])
      return this.objects[entity]
    },
    getPlaceCollection () {
      const entity = this.$entities.placeObjects.find(x => this.objects[this.getCollectionName(x)])
      return this.objects[this.getCollectionName(entity)]
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    handleEvent (value) {
      if (!value.event) {
        return
      }

      const events = {
        'counter.submit': (data) => {
          this.amount = Number(data.value || 0)

          return this.handleBarcode(this.barcode)
        },
        'counter.focus': () => {
          this.$emit('focus', true)
        },
        'counter.focusOut': () => {
          this.$emit('focusOut', true)
        }
      }

      if (typeof events[value.event] === 'function') {
        return events[value.event](value.data)
      }

      console.error(`Event is not recognized! ${value.event}`)
    },
    reduceData (ignoredKeys = [], defaultValue = {}) {
      return Object.keys(this.data)
          .filter(key => !ignoredKeys.includes(key))
          .reduce((acc, key) => {
            if (!this.data[key]) {
              return acc
            }

            return {
              ...acc,
              [key]: typeof this.data[key] === 'object' && !Array.isArray(this.data[key])
                  ? this.data[key].id
                  : this.data[key]
            }
          }, { ...defaultValue })
    }
  }
}
</script>
